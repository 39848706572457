<template>
  <div>
    <!-- <v-card variant="flat" class="terms-card"> -->
    <v-card-actions class="mt-n4"
      ><v-icon
        color="white"
        size="large"
        @click="redirect('profilePage')"
        class="mt-4"
        >mdi-chevron-left</v-icon
      >
      <v-spacer />
      <v-card-text class="signInTitle">YOUR REFERRAL LINK</v-card-text>
      <v-spacer />
    </v-card-actions>

    <v-card
      variant="outlined"
      align="center"
      justify="center"
      class="mx-auto"
      color="white"
      max-width="600"
    >
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="3">
            <v-img :src="require('@/assets/whatsapp.png')" width="70"></v-img>
          </v-col>
          <v-col cols="3">
            <v-img :src="require('@/assets/gmail.png')" width="70"></v-img>
          </v-col>
          <v-col cols="3">
            <v-img :src="require('@/assets/instagram.png')" width="70"></v-img>
          </v-col>
          <v-col cols="3">
            <v-img :src="require('@/assets/facebook.png')" width="70"></v-img>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="mt-4">
          <span :class="screenSize === 'Large' ? 'textLeft' : 'small-textLeft'">
            Share this referral link to your friends and followers:
          </span>
        </v-row>

        <v-row align="center" justify="center" class="mt-4">
          <span  :class="screenSize === 'Large' ? 'textLeft' : 'small-textLeft'">
            {{ referralLink ? referralLink : "N/A" }}
          </span>

          <v-fade-transition mode="out-in">
            <v-btn
              :key="`subscribe-${isSubscriber}`"
              :border="`thin ${isSubscriber ? 'success' : 'success'}`"
              :color="isSubscriber ? 'success' : 'success'"
              :prepend-icon="isSubscriber ? 'mdi-check' : 'mdi-clipboard'"
              :slim="isSubscriber"
              :text="isSubscriber ? 'Copied' : 'Copy'"
              :variant="isSubscriber ? 'plain' : 'tonal'"
              class="me-2 text-none ml-6 mt-4"
              size="small"
              flat
              @click="copyText"
            ></v-btn>
          </v-fade-transition>
        </v-row>
      </v-card-text>
    </v-card>
  </div>

  <!-- </div> -->
</template>

<script>
import { AuthService } from "@/modules/auth";
export default {
  name: "testPage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$store.dispatch("graph/getReferralLink", {
          user_id: JSON.parse(AuthService.user).user_id,
        });
      }
    });
  },

  data() {
    return {
      dialog: true,
      isValid: false,
      screenSize: "",
      isSubscriber: false,
    };
  },

  computed: {
    referralLink() {
      return this.$store.getters["graph/graphGetters"]("referralLink");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    redirect(val) {
      this.$router.push({ name: val });
    },
    copyText() {
      this.isSubscriber = true;

      const tempInput = document.createElement("input");
      tempInput.value = this.referralLink;
      document.body.appendChild(tempInput);

      // Select the text
      tempInput.select();
      tempInput.setSelectionRange(0, 99999);

      document.execCommand("copy");

      document.body.removeChild(tempInput);

    },
  },

  watch: {},
};
</script>

<style>
@import url("../style.css");
</style>
