import { createVuetify } from 'vuetify';
import * as components from 'vuetify/lib/components';
import 'vue-material-design-icons/styles.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.min.css'



export default createVuetify({
  components,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#3f51b5',
          secondary: '#b0bec5',
        },
      },
      dark: {
        colors: {
          primary: '#0d0f24',
          secondary: '#607D8B',
        },
      },
    },
  },
  icons: {
    iconfont: "md, mdi",
  },
});


