<template>
    <div class="center-horizontally">
        <v-card-action><v-icon color="white" size="large" @click="redirect('profilePage')"
                class="mt-4">mdi-chevron-left</v-icon>
        </v-card-action>
        <v-card-text class="signInTitle mt-2">FAQs</v-card-text>

        <v-card-text  :class="screenSize === 'Large' ? 'sub-label mt-n6' : 'small-sub-label mt-n6'">
            <div :class="screenSize === 'Large' ? 'sub-label mt-n6' : 'small-sub-label mt-n6'">
                <div class="live-Sport ml-n1 mb-2 "> How to invite friends and get cash?</div>

                Inside of menu, there is "invite friends". Click it and share your link to your friends through
                Facebook, Twitter or WhatsApp. And you can get cash after your friends place the bets amount limited as
                the invite friends page shows.
            </div>
            <div :class="screenSize === 'Large' ? 'sub-label mt-n6' : 'small-sub-label mt-n6'">
                <div class="live-Sport ml-n1 mb-2 "> Which game is easy to win?</div>

                With our top-notch games, all are available to have a big win. You can just choose what you like to
                play.
            </div>
            <div :class="screenSize === 'Large' ? 'sub-label mt-n6' : 'small-sub-label mt-n6'">
                <div class="live-Sport ml-n1 mb-2 "> How to register?</div>

                Opening our game app or website, you can just register with enter your phone number and password. Then
                click create account and wait for verification code through mpesa. After receiving code, copy it and
                then complete the registration.
            </div>
            <div :class="screenSize === 'Large' ? 'sub-label mt-n6' : 'small-sub-label mt-n6'">
                <div class="live-Sport ml-n1 mb-2 ">  How to deposit?</div>
                Just click deposit at the top right on your app or website. There are two ways for you to deposit.
                Online deposit and pay bill deposit. Follow the instructions as deposit page given. Then your deposit
                can be successful.
            </div>
            <div :class="screenSize === 'Large' ? 'sub-label mt-n6' : 'small-sub-label mt-n6'">
                <div class="live-Sport ml-n1 mb-2 "> How to withdraw?</div>
                
                Click menu and then find withdrawals. Input your amount and confirm. Then your amount can be withdrawn. Before withdrawing, make sure that your betting reaches 500 ksh.
            </div>
        </v-card-text>
    </div>
</template>

<script>
export default {
    name: "testPage",

    data() {
        return {
            isValid: false,

            screenSize: "",
        };
    },

    computed: {},

    mounted() {
        this.checkScreenSize(); // Check screen size initially
        window.addEventListener("resize", this.checkScreenSize);
    },
    methods: {
        checkScreenSize() {
            const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
            this.screenSize = isSmallScreen ? "Small" : "Large";
        },

        redirect(val) {
            this.$router.push({ name: val });
        },
    },

    watch: {},
};
</script>

<style>
@import url("../style.css");
</style>
