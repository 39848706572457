import leftSide from "../graph/components/leftSide.vue";
import dashboard from "../dashboard/views/dashboard";
// import auth from '../auth/middleware/Auth';
import LandingPage from "../graph/components/LandingPage.vue";
import Login from "../Main/components/Login.vue";
import Register from "../Main/components/Register.vue";
import resetPassword from "../Main/components/Reset.vue";
import otpPage from "../Main/components/OTP.vue";
// import Deposit from "../Main/components/Deposit.vue";
import withdraw from "../Main/components/withdraw.vue"
import profilePage from "../Main/components/profilePage.vue";
// import test from "../Main/components/Deposit.vue";
import forgot from "../Main/components/forgot.vue";
import fairCrash from "../graph/components/fairCrash.vue";
import coinFlip from "../graph/components/coinFlip.vue";
import rockPaper from "../graph/components/rockPaper.vue";
import Promotion from "../Main/components/promotion.vue";
import manualRec from "../Main/components/manualDep.vue";
import FAQ from "../Main/components/FAQ.vue";
import TERMS from "../Main/components/terms.vue";
import CONTACTS from "../Main/components/contactUs.vue";
import BETHISTORY from "../Main/components/betHistory.vue";
import TRANSHISTORY from "../Main/components/transactionHistory.vue";
import Referral from "../Main/components/referral.vue";
// import MainPage from "../graph/components/mainPage.vue";

import depositMoney from "../Main/components/addMoney.vue";

const graphRoutes = [
  {
    path: "/",
    component: dashboard,
    children: [
      {
        path: "",
        name: "LandingPage",
        component: LandingPage,
        meta: { requiresAuth: false },
        children: [
          // {
          //   path: "/deposit",
          //   name: "Deposit",
          //   component: Deposit,
          //   meta: { requiresAuth: true },
          // },
        ],
      },
      {
        path: "/fair-crash",
        name: "fairCrash",
        component: fairCrash,
        meta: { requiresAuth: true },
      },
      {
        path: "/coin-flip",
        name: "coinFlip",
        component: coinFlip,
        meta: { requiresAuth: true },
      },

      {
        path: "/deposit-money",
        name: "depositMoney",
        component: depositMoney,
        meta: { requiresAuth: true },
      },

      {
        path: "/withdraw",
        name: "withdraw",
        component: withdraw,
        meta: { requiresAuth: true },
      },


      {
        path: "/manual-rec",
        name: "manualRec",
        component: manualRec,
        meta: { requiresAuth: true },
      },
      {
        path: "/faqs",
        name: "FAQ",
        component: FAQ,
        meta: { requiresAuth: true },
      },
      {
        path: "/terms-conditions",
        name: "TERMS",
        component: TERMS,
        meta: { requiresAuth: true },
      },
      {
        path: "/contact-us",
        name: "CONTACTS",
        component: CONTACTS,
        meta: { requiresAuth: true },
      },
      {
        path: "/bet-history",
        name: "BETHISTORY",
        component: BETHISTORY,
        meta: { requiresAuth: true },
      },
      {
        path: "/referral",
        name: "Referral",
        component: Referral,
        meta: { requiresAuth: true },
      },
      {
        path: "/transaction-history",
        name: "TRANSHISTORY",
        component: TRANSHISTORY,
        meta: { requiresAuth: true },
      },
      {
        path: "/rock-paper",
        name: "rockPaper",
        component: rockPaper,
        meta: { requiresAuth: true },
      },
      {
        path: "/promotion",
        name: "Promotion",
        component: Promotion,
        meta: { requiresAuth: false },
      },
      {
        path: "/signup",
        name: "signUp",
        component: Register,
      },

      {
        path: "/signin",
        name: "signIn",
        component: Login,
        meta: { requiresAuth: false },
      },

      {
        path: "/reset-password/:code",
        name: "resetPassword",
        component: resetPassword,
        meta: { requiresAuth: false },
      },

      {
        path: "/otp-code/:code",
        name: "otpPage",
        component: otpPage,
        meta: { requiresAuth: false },
      },

      {
        path: "/profile-page",
        name: "profilePage",
        component: profilePage,
        meta: { requiresAuth: true },
      },
      // {
      //   path: "test",
      //   name: "test",
      //   component: test,
      // },

      {
        path: "/live",
        name: "leftSide",
        component: leftSide,
        meta: { requiresAuth: true },
      },
      // {
      //   path: "/live",
      //   name: "MainPage",
      //   component: MainPage,
      //   meta: { requiresAuth: true },
      // },

      {
        path: "/forgot-password",
        name: "forgot",
        component: forgot,
        meta: { requiresAuth: false },
      },
    ],
  },
];

export default graphRoutes;
