<template>
  <div class="center-horizontally">
    <!-- <v-card-text class="signInTitle mt-4">Promotion</v-card-text> -->

    <v-row class="mt-6 mx-1">
      <v-img :src="require('@/assets/welcome.png')" class="img" />
    </v-row>
    <v-row class="mt-6 ml-n2">
      <v-card-title class="dep-text"> Deposit Funds </v-card-title>
      <v-card-text class="mt-n1 sub-text">
        Transfer Money from M-PESA account to your Sakata wallet.
      </v-card-text>

      <v-card-text class="mt-n6 sub-text"> Select Top up amount(instant) </v-card-text>
    </v-row>

    <v-row class="mt-6 mx-2 w-100">
      <v-btn-toggle
        v-model="toggle"
        variant="tonal"
        divided
        class="w-100 d-flex justify-space-between mx-2 ml-n1 toggle-button"
      >
        <v-btn size="small" class="flex-grow-1">50</v-btn>
        <v-btn size="small" class="flex-grow-1">100</v-btn>
        <v-btn size="small" class="flex-grow-1">250</v-btn>
        <v-btn size="small" class="flex-grow-1">500</v-btn>
        <v-btn size="small" class="flex-grow-1">1000</v-btn>
      </v-btn-toggle>
    </v-row> 

    <v-row class="">
      <v-card-text class="login-form">
        <v-form ref="loginForm" v-model="isValid">
          <div class="signInLeft mt-n6 sub-text">Enter Top Up amount</div>

          <!--Amount-->

          <v-text-field
            ref="amount"
            density="compact"
            :rules="rules.required"
            v-model="formData.amount"
            class="mx-0 mt-3 inverted-text-field"
            placeholder=""
            variant="outlined"
          ></v-text-field>
        </v-form>
      </v-card-text>
    </v-row>
    <v-row class="mx-2">
      <v-btn
        @click="performAction(toggle)"
        block
        size="x-large"
        :disabled="!isValid"
        class="withdraw-btn"
        color="#3f2360"
      >
        <span class="login--text" v-if="!spinner">Top up Now</span>
        <v-progress-circular
          v-if="spinner"
          indeterminate
          color="purple"
        ></v-progress-circular>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
// import { AuthService } from "@/modules/auth";
export default {
  name: "promotionPage",

  data() {
    return {
      toggle: null,
      isValid: false,
      formData: {
        amount: "",
      },
      showPassword: false,
      rules: {
        required: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
        ],
      },
    };
  },

  computed: {
    spinner() {
      return this.$store.getters["graph/graphGetters"]("spinner");
    },
  },

  mounted() {
    // this.checkScreenSize(); // Check screen size initially
    // window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    // checkScreenSize() {
    //   const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
    //   this.screenSize = isSmallScreen ? "Small" : "Large";
    // },
  },

  watch: {},
};
</script>

<style>
@import url("../style.css");
</style>
