<template>
  <div class="center-horizontally">
    <!-- <v-card-text class="signInTitle mt-4">Promotion</v-card-text> -->

    <v-row class="mt-6 mx-1">
      <v-img :src="require('@/assets/welcome.png')" class="img" />
    </v-row>
    <v-row class="mt-6 ml-n2">
      <v-card-title class="dep-text ml-n2"> Withdraw Funds </v-card-title>
      <v-card-text class="mt-n1 sub-text ml-n1">
        Transfer Money from M-PESA account from your Sakata wallet.
      </v-card-text>
    </v-row>

    <v-row class="mt-n4">
      <v-card-text class="login-form">
        <v-form ref="loginForm" v-model="isValid">
          <div class="signInLeft mt-n6 sub-text">Enter Withdrawal amount</div>

          <!--Amount-->

          <v-text-field
            ref="amount"
            density="compact"
            :rules="rules.required"
            v-model="formData.amount"
            class="mx-0 mt-3"
            placeholder=""
            variant="outlined"
          ></v-text-field>
        </v-form>
      </v-card-text>
    </v-row>
    <v-row >
        <v-card-text class="mt-1 sub-text">
            <p class="sub-text mt-n6 title-text">Instructions:</p>
      <p class="sub-text">Minimum Withdrawal amount: 100/-</p>
      <p class="sub-text">Maximum Withdrawal amount: 150,000/-</p>
      <p class="sub-text">Daily Transaction Limit: 300,000/-</p>
        </v-card-text>
      
    </v-row>
    <v-row class="sub-text mt-3 mb-3">
      <v-card-text class="mt-n1 sub-text">
        Withholding Tax (WHT): As provided for by the Income Tax Act, Cap 472,
        all gaming companies are required to withhold winnings at a rate of 20%.
        This is the Withholding Tax. In compliance with the law, we will deduct
        and remit to KRA 20% of all winnings.
      </v-card-text>
    </v-row>

    <v-row class="mx-1 mt-3">
      <v-btn
        @click="performAction(toggle)"
        block
        size="large"
        :disabled="!isValid"
        class="withdraw-btn"
        color="#3f2360"
      >
        <span class="login--text" v-if="!spinner">Withdraw Now</span>
        <v-progress-circular
          v-if="spinner"
          indeterminate
          color="purple"
        ></v-progress-circular>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
// import { AuthService } from "@/modules/auth";
export default {
  name: "promotionPage",

  data() {
    return {
      toggle: null,
      isValid: false,
      formData: {
        amount: "",
      },
      showPassword: false,
      rules: {
        required: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
        ],
      },
    };
  },

  computed: {
    spinner() {
      return this.$store.getters["graph/graphGetters"]("spinner");
    },
  },

  mounted() {
    // this.checkScreenSize(); // Check screen size initially
    // window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    // checkScreenSize() {
    //   const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
    //   this.screenSize = isSmallScreen ? "Small" : "Large";
    // },
  },

  watch: {},
};
</script>

<style>
@import url("../style.css");
</style>
