<template>
  <v-card variant="outlined" class="mx-auto profile-card mt-3">
    <!-- <v-spacer /> {{
                User.balance ? formatNumberWithCommas(User.balance) : "N/A"
              }} <v-spacer /> -->
  </v-card>

  <v-row class="position-relative mt-3 mb-3">
    <v-img :src="require('@/assets/credit.png')" class="w-100" />

    <!-- Wrapper for text overlay on the image -->
    <div class="overlay-text">
      <span class="credit-text">{{
        User.username ? User.username : "N/A"
      }}</span>
      <span class="credit-text">
        Balance :
        {{ User.balance ? formatNumberWithCommas(User.balance) : "N/A" }}
      </span>
    </div>
  </v-row>

  <v-card-text class="mt-5">
    <v-row>
      <v-col cols="1" class="ml-5">
        <div
          :class="screenSize === 'Large' ? 'signInLeft ' : 'small-signInLeft'"
        >
          <v-icon :color=" screenSize === 'Large' ? 'black' : 'white'" size="large">mdi-file-outline</v-icon>
        </div>
      </v-col>
      <v-col cols="9" @click="redirect('TRANSHISTORY')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          Transaction History
        </div>
      </v-col>
      <v-col cols="1" @click="redirect('TRANSHISTORY')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <v-icon size="small" :color=" screenSize === 'Large' ? 'black' : 'white'" class="ml-n3"
            >mdi-chevron-right</v-icon
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="ml-5">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <v-icon :color=" screenSize === 'Large' ? 'black' : 'white'" size="large">mdi-clock-outline</v-icon>
        </div>
      </v-col>
      <v-col cols="9" @click="redirect('BETHISTORY')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          Bet History
        </div>
      </v-col>
      <v-col cols="1" @click="redirect('BETHISTORY')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <v-icon :color=" screenSize === 'Large' ? 'black' : 'white'" size="small" class="ml-n3"
            >mdi-chevron-right</v-icon
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="ml-5">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <v-icon :color=" screenSize === 'Large' ? 'black' : 'white'" size="large">mdi-cash-multiple</v-icon>
        </div>
      </v-col>
      <v-col cols="6" @click="redirect('Referral')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          Invite Friends
        </div>
      </v-col>
      <v-col cols="4" @click="redirect('Referral')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <span
            :class="
              screenSize === 'Large'
                ? 'get-cash-text ml-12'
                : 'small-get-cash-text'
            "
            >Get Cash</span
          >
          <v-icon
            size="small"
            :color=" screenSize === 'Large' ? 'black' : 'white'"
            :class="screenSize !== 'Large' ? 'ml-4' : ''"
            >mdi-chevron-right</v-icon
          >
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"  class="ml-5">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <v-icon :color=" screenSize === 'Large' ? 'black' : 'white'" size="large">mdi-lightbulb-outline</v-icon>
        </div>
      </v-col>
      <v-col cols="9" @click="redirect('FAQ')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          FAQs
        </div>
      </v-col>
      <v-col cols="1" @click="redirect('FAQ')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <v-icon :color=" screenSize === 'Large' ? 'black' : 'white'" size="small" class="ml-n3"
            >mdi-chevron-right</v-icon
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="ml-5">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <v-icon :color=" screenSize === 'Large' ? 'black' : 'white'" size="large">mdi-comment-processing</v-icon>
        </div>
      </v-col>
      <v-col cols="9" @click="redirect('CONTACTS')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          Contact Us
        </div>
      </v-col>
      <v-col cols="1" @click="redirect('CONTACTS')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <v-icon size="small" :color=" screenSize === 'Large' ? 'black' : 'white'" class="ml-n3"
            >mdi-chevron-right</v-icon
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="ml-5">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <v-icon :color=" screenSize === 'Large' ? 'black' : 'white'" size="large">mdi-book-open-variant</v-icon>
        </div>
      </v-col>
      <v-col cols="9" @click="redirect('TERMS')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          Terms and Conditions
        </div>
      </v-col>
      <v-col cols="1" @click="redirect('TERMS')">
        <div
          :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
        >
          <v-icon size="small" class="ml-n3">mdi-chevron-right</v-icon>
        </div>
      </v-col>
    </v-row>
  </v-card-text>

  <div class="mb-8 mt-6">
    <v-card-actions class="mt-n3">
      <v-spacer />
      <v-btn block size="x-large" class="wd-login-btn" @click="logOut()"
        ><span class="login--text">Logout</span>
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </div>
</template>

<script>
import { AuthService } from "@/modules/auth";
// import footerPage from "./footer.vue";
export default {
  name: "profilePage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$store.dispatch("auth/getUser", {
          phone: JSON.parse(AuthService.user).phone,
        });
      }
    });
  },

  data() {
    return {
      isValid: false,
      loginState: false,
      screenSize: "",
      visible: false,
      tab: 0,
      variants: [
        { variant: "outlined", amount: 48, text: "Get 50", active: false },
        { variant: "outlined", amount: 95, text: "Get 100", active: false },
        { variant: "outlined", amount: 477, text: "Get 500", active: false },
        { variant: "outlined", amount: 977, text: "Get 1000", active: false },
      ],
      formData: {
        amount: "",
      },
      showPassword: false,
      rules: {
        required: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
        ],
      },
    };
  },

  computed: {
    alertMessage() {
      return this.$store.getters["auth/authGetters"]("alert");
    },
    isLoggedIn() {
      return AuthService.check();
    },
    userDetails() {
      return JSON.parse(AuthService.user);
    },

    User() {
      return this.$store.getters["auth/authGetters"]("userD");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    this.alertMessage.status = "";
    this.alertMessage.message = "";
  },
  methods: {
    Login() {
      if (!this.isValid) {
        this.$refs.loginForm.validate();
      } else {
        this.loginState = true;
        this.$store.dispatch("auth/login", this.formData);
      }
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    dismissAlert() {
      // Clear the alert
      this.alertMessage.status = "";
      this.alertMessage.message = "";
    },
    clearDeposit() {
      this.$refs.loginForm.reset();
      for (let i = 0; i < this.variants.length; i++) {
        this.variants[i].active = false;
      }
    },

    activateButton(val) {
      for (let i = 0; i < this.variants.length; i++) {
        if (this.variants[i] !== val) {
          this.variants[i].active = false;
        }
      }
      val.active = !val.active;
      this.formData.amount = val.amount;
    },
    logOut() {
      AuthService.logout();
    },
    redirect(val) {
      this.$router.push({ name: val });
    },
    formatNumberWithCommas(number) {
      const val = Number(number);
      return val.toLocaleString();
    },
  },

  watch: {
    alertMessage: {
      handler: function () {
        if (this.alertMessage.status !== 1000) {
          this.loginState = false;
        }
      },
    },
  },
};
</script>

<style>
@import url("../style.css");
</style>
